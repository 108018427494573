<script setup>
import { NButton } from 'naive-ui'
import { ref } from 'vue';

const label = ref('Reset');

const resetCode = (params) => {
  console.debug('reset code', params);
}
</script>

<template>
  <div>
    <n-button type="primary" size="tiny" round @click="resetCode(params)">
      <span v-if="label">{{ label }}</span>
    </n-button>
  </div>
</template>

<style scoped>

</style>