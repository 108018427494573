<script setup>
import { onMounted, computed, ref } from 'vue';
import { NMenu } from 'naive-ui'
import { useRouter } from 'vue-router';

const router = useRouter();
const selectedItem = ref(router.currentRoute.value.path?.split('/')[1]);
const menuOptions = [
  {
    label: "Vinyl manager",
    key: 'vinyl-admin',
  },
  // {
  //   label: "Users",
  //   key: 'users',
  // }
]
const footerMenu = [
  {
    label: "Log out",
    key: 'logout',
  }
]
const handleUpdateValue = (value) => {
  router.push({ path: `/${value}` });
}

</script>

<template>
  <div class="nav-container">
    <div class="nav-top">
      <img src="https://digitalvinyl.saddle-creek.com/assets/logo.png" alt="logo" />
    </div>
    <div class="nav-main">
      <n-menu
        :options="menuOptions"
        v-model:value="selectedItem"
        @update:value="handleUpdateValue"
      />
    </div>

    <div class="nav-footer">
        <n-menu
          :options="footerMenu"
          @update:value="handleUpdateValue"
      />
    </div>
  </div>
</template>

<style scoped>
.nav-top {
  padding: 10px 0;
}
.nav-row {
  display: flex;
  align-items: center;
  border-radius: 3px;
  transition: all 250ms ease;
  cursor: pointer;
  padding: 2px 10px;
}
.nav-row > svg {
  margin-right: 5px;
}
.nav-row:hover {
  background-color: #404040;
}
.nav-container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.nav-main {
  flex: 1;
}
.nav-footer {
  padding-bottom: 20px;
}
</style>