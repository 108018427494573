<script setup>
import { ref } from 'vue';

import { NButton, NIcon } from 'naive-ui'
import { Add, TrashOutline } from '@vicons/ionicons5'

const isInputRow = ref(false);
const label = ref(null);
const init = (params) => {
  if (!params.data.email || !params.data.name) {
    isInputRow.value = true;
    label.value = 'Add user';
  }
};

const hideButton = ['nickjbernal@gmail.com', 'robb@saddle-creek.com']

</script>

<template>
  <div class=row-wrapper>
    {{ init(params) }}
    <n-button type="primary" size="tiny" round @click="params.action(params)" v-if="!hideButton.includes(params.data.email)">
      <template #icon>
        <n-icon>
          <trash-outline v-if="!isInputRow" />
          <add v-else />
        </n-icon>
      </template>
      <span v-if="label">{{ label }}</span>
    </n-button>
  </div>
</template>

<style scoped>
.row-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
</style>