import { createRouter, createWebHistory } from 'vue-router';
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

import Login from '@/pages/login/Login.vue';
import VinylManager from '@/pages/vinyl-manager/VinylManager.vue';
import UserManagement from '@/pages/users/UserManagement.vue';

const firebaseConfig = {
  apiKey: "AIzaSyCCWfSsR0mXqOb6xPL1eixmmqKOG1xJyew",
  authDomain: "njb-sc-admin.firebaseapp.com",
  projectId: "njb-sc-admin",
  storageBucket: "njb-sc-admin.appspot.com",
  messagingSenderId: "888823367356",
  appId: "1:888823367356:web:c9f26320b1a2c94e5f72b9"
};
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);

const routes = [
  {
    path: '/',
    component: Login,
    name: 'Login',
  },
  {
    path: '/login',
    component: Login,
    name: 'Login',
  },
  {
    path: '/logout',
    component: Login,
    name: 'Logout',
  },
  {
    path: '/vinyl-admin',
    component: VinylManager,
    name: 'Vinyl admin',
  },
  {
    path: '/users',
    component: UserManagement,
    name: 'User management',
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (!auth.currentUser && to.path !== '/login') {
    next('/login');
  } else {
    console.debug('Current user', auth.currentUser)
    next();
  }
});

export default router;
