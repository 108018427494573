<script setup>
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import { AgGridVue } from "ag-grid-vue3"; // Vue Grid Logic
import { ref, onMounted, reactive } from 'vue';
import { storeToRefs } from 'pinia';
import { NButton, NIcon } from 'naive-ui'
import { CaretForward, CaretDown } from '@vicons/ionicons5'

import { useAdminStore } from '@/stores/admin-store.js';
import ActionsRenderer from '@/pages/vinyl-manager/ActionsRenderer.vue';
import ReleaseView from '@/pages/vinyl-manager/ReleaseView.vue';
defineExpose({ActionsRenderer})


const adminStore = useAdminStore();
const { codes, artists, releases } = storeToRefs(adminStore);
const isExpanded = reactive({});
const isReleaseExpanded = reactive({});

const artistCollapser = ref([]);

const getReleases = (artist) => {
  const releasesForArtist = releases.value.filter(release => release.artist_id === artist.id);
  return releasesForArtist;
}

const toggleReleases = (artist) => {
  isExpanded[artist] = !isExpanded[artist];
}

onMounted(() => {
  adminStore.sectionTitle = 'Vinyl admin';
  adminStore.loadArtists();
  adminStore.loadReleases();
})
</script>

<template>
  <div class="grid-container">
    <div v-for="artist in artists" :key="artist" ref="artistCollapser">
      <div @click="toggleReleases(artist.id)" class="artist-name clickable">
        <n-icon><caret-down v-if="isExpanded[artist.id]" /><caret-forward v-else /></n-icon>
        <div class="artist-title">{{ artist.name }}</div>
      </div>

      <div v-if="isExpanded[artist.id]" class="releases">
        <div v-for="release in getReleases(artist)" :key="release" class="release">

          <ReleaseView :release="release" :artist="artist" />

        </div>
      </div>

    </div>
  </div>
</template>

<style scoped>
.sub-info {
  font-size: 10px;
  border-radius: 5px;
  background-color: var(--sc-blue-dark);
  padding: 2px 10px;
  margin-right: 10px;
}
.release-title:hover {
  text-decoration: underline;
  background-color: var(--sc-blue-dark);
}
.left-side,
.right-side  {
  display: flex;
  align-items: center;
}
.clickable {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  transition: all 250ms ease-in-out;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 5px 0;
}
.artist-title,
.release-title {
  font-weight: 600;
}
.left-side > *:first-child,
.clickable > *:first-child {
  margin-right: 10px;
}
.artist-name:hover {
  text-decoration: underline;
  background-color: var(--sc-blue-dark);
}
.releases-container {
  padding: 10px 0;
}
.release-clickable {
  display: flex;
  justify-content: space-between;
}
.releases {
  padding: 0px 20px;
}

.grid-container {
  width: 100%;
  height: 100%;
}
</style>