<script setup>

import { storeToRefs } from 'pinia';
import { onMounted, computed, ref } from 'vue';
import { AgGridVue } from "ag-grid-vue3";
import { useMessage, useDialog } from 'naive-ui'

import { useAdminStore } from '@/stores/admin-store.js';
import ActionsRenderer from '@/components/grids/ActionsRowRenderer.vue';

const message = useMessage();
const dialog = useDialog();
const adminStore = useAdminStore();
const { users } = storeToRefs(adminStore);

const getUsers = computed(() => {
  return users.value;
});

const gridApi = ref(null);
const gridColumnApi = ref(null);
const inputRow = ref({});
const newUserEmail = ref(null);
const newUserName = ref(null);

function validateEmail(email) {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
}

const setInputRow = (data) => {
  inputRow.value = data;
  gridApi.value.setGridOption('pinnedTopRowData', [inputRow.value]);
}

const confirmRemove = (params) => {
  const email = params.node.data.email;
  dialog.warning({
    title: 'Remove user',
    content: `Are you sure you want to remove ${email}?`,
    positiveText: 'Confirm',
    negativeText: 'Cancel',
    onPositiveClick: () => {
      adminStore.removeUser(email);
    },
    onNegativeClick: () => {}
  })
}

const saveUser = async () => {

  if (!validateEmail(newUserEmail.value)) {
    message.error('Please enter a valid email address...');
  } else if (!newUserName.value) {
    message.error('Please enter a name...');
  } else {
    // Save user
    const result = await adminStore.addUser(newUserEmail.value, newUserName.value);
    if (result.status) {
      message.success(`User ${newUserEmail.value} added successfully!`);
      console.debug('** users', adminStore.users);
      setInputRow({});
      gridApi.value.setRowData(users.value);
    } else {
      message.error(`${result.message}`);
    }
  }
}

const userCallback = (params) => {
  if (params.node.rowPinned === 'top') {
    saveUser();
  } else {
    confirmRemove(params);
  }
}

const defaultColDef = {
  flex: 1,
  editable: (params) => {
    const editableFields = ['email', 'name'];
    return params.node.rowPinned === 'top' && editableFields.includes(params.colDef.field);
  },
  valueFormatter: (params) => {
    if (isEmptyPinnedCell(params)) return createPinnedCellPlaceholder(params);
    return undefined;
  }
}
const colDefs = [
  { field: "email" },
  { field: "name" },
  { field: "last_login", headerName: "Last login", valueFormatter: ({value}) => adminStore.formatDate(value), minWidth: 300 },
  {
    field: 'actions',
    cellRenderer: 'ActionsRenderer',
    cellRendererParams: {
      action: userCallback,
    }
  },
]
const gridOptions = {
  stopEditingWhenCellsLoseFocus: true,
  singleClickEdit: true,
  rowHeight: 35,
  pinnedTopRowData: [inputRow],
  isFullWidthRow: (rowNode) => rowNode.rowNode?.data.actionsRow === true,
  getRowStyle: ({ node }) => node.rowPinned ? {  'font-style': 'italic', 'background-color': '#303030', fontSize: '12px', cursor: 'pointer', } : 0,
  onCellEditingStopped: (params) => {
    if (params.column.colId === 'email') {
      newUserEmail.value = params.newValue;
    } else if (params.column.colId === 'name') {
      newUserName.value = params.newValue;
    }
  },
}

function isEmptyPinnedCell({ node, value }) {
  return (
    (node.rowPinned === 'top' && value == null) ||
    (node.rowPinned === 'top' && value == '')
  );
}

function createPinnedCellPlaceholder({ colDef }) {
  if (colDef.field.toUpperCase() === 'EMAIL') return 'Add new user (enter email)...';
  else if (colDef.field === 'remove_user') return null;
  return 'Enter ' + colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...';
}

const onGridReady = (params) => {
  gridApi.value = params.api;
  gridColumnApi.value = params.columnApi;
}
onMounted(() => {
  adminStore.loadUsers();
  adminStore.sectionTitle = 'Users';
});

defineExpose({ActionsRenderer})
</script>

<template>
  <div class="users-wrapper ag-theme-quartz-dark">
    <ag-grid-vue
      class="grid-container"
      :rowData="adminStore.getUsers"
      :defaultColDef="defaultColDef"
      :columnDefs="colDefs"
      :gridOptions="gridOptions"
      @grid-ready="onGridReady"
    >
    </ag-grid-vue>
  </div>
</template>

<style scoped>
.grid-container {
  width: 100%;
  height: 100%;
}
.users-wrapper {
  height: 100%;
  width: 100%;
}
</style>