<script setup>
import { useAdminStore } from '@/stores/admin-store.js';

const adminStore = useAdminStore();
</script>

<template>
  <div>
    <div class="left-side">
      {{ adminStore.sectionTitle }}
    </div>
  </div>
</template>