<script setup>
import axios from 'axios';
import { onMounted, ref } from 'vue';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useRouter } from 'vue-router';
import { useMessage } from "naive-ui";
import { NInput, NButton } from 'naive-ui'
import { useAdminStore } from '@/stores/admin-store.js';

const adminStore = useAdminStore();
const router = useRouter();
const auth = getAuth();
const email = ref(null);
const password = ref(null);

const signIn = () => {
  signInWithEmailAndPassword(auth, email.value, password.value)
    .then((userCredential) => {
      // Signed in 
      const user = userCredential.user;
      sendLoginRequest(userCredential);
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
    });
}

const sendLoginRequest = async (credentials) => {
  const token = await credentials.user.getIdToken();
  const result = await axios.post(`${adminStore.SERVER}/login`, { tokenId: token }, { withCredentials: true });
  console.debug('\n\n login result:', result);
  router.push({ path: '/vinyl-admin' });
}

const logout = () => {
  auth.signOut().then(() => {
    // Sign-out successful.
    // You can perform any necessary actions after successful logout.
    console.log('User logged out successfully');
  }).catch((error) => {
    // An error happened.
    console.error('Error occurred during logout:', error);
  });
};

const handleKeyDown = (e) => {
  if (e.key === 'Enter') {
    if (!email.value || !password.value) return;
    signIn();
  }
}

onMounted(() => {
  console.debug('mounted')
  if (router?.currentRoute?.value?.path === '/logout') {
    logout();
  }
});
</script>

<template>
  <div class="login-view">
    <div class="icon-container">
      <img src="https://digitalvinyl.saddle-creek.com/assets/logo.png" class="logo" alt="logo" />
    </div>
    <div class="form-container">
      <n-input
        type="text"
        show-password-on="mousedown"
        placeholder="Email"
        :maxlength="100"
        v-model:value="email"
        @keydown="handleKeyDown"
      />
      <n-input
        type="password"
        show-password-on="mousedown"
        placeholder="Password"
        :maxlength="30"
        v-model:value="password"
        @keydown="handleKeyDown"
      />
      <n-button type="primary" @click="signIn">
        Sign in
      </n-button>
    </div>
  </div>
</template>


<style scoped>
.logo {
  margin-bottom: 40px;
}
.icon-container {
  display: flex;
  justify-content: center;
}
.login-view {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.form-container {
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #cacaca33;
  padding: 50px;
  min-width: 350px;
  border-radius: 5px;
}
.form-container > * {
  margin: 2px 0;
}
</style>