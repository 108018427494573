<script setup>
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import { AgGridVue } from "ag-grid-vue3"; // Vue Grid Logic
import { ref, onMounted, reactive, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { NInput, NIcon, useMessage } from 'naive-ui'
import { CaretForward, CaretDown } from '@vicons/ionicons5'

import { useAdminStore } from '@/stores/admin-store.js';
import ActionsRenderer from '@/pages/vinyl-manager/ActionsRenderer.vue';
defineExpose({ActionsRenderer})


const adminStore = useAdminStore();
const { codes, artists, releases } = storeToRefs(adminStore);
const isReleaseExpanded = reactive({});
const message = useMessage();

const props = defineProps({
  release: {
    type: Object,
    required: true
  },
  artist: {
    type: Object,
    required: true
  }
})

const defaultColDef = {
  flex: 1,
  filter: 'agTextColumnFilter',
  enableCellTextSelection: true,
}
const colDefs = ref([
  { field: "artist", valueGetter: (params) => props.artist.name},
  { field: "title" },
  { field: "upc", headerName: "UPC", },
  { field: "code" },
  { field: "dl_time", headerName: "D/L time" },
  { field: "dl_email", headerName: "Email" },
  { field: "dl_ip", headerName: "IP" },
  // { field: "dl_useragent", headerName: "User agent"},
  { field: "downloads_remaining", headerName: "Remaining", filter: 'agNumberColumnFilter' },
  // { field: "release_date", },
  { field: "actions", cellRenderer: 'ActionsRenderer' }
]);


const handleCellClick = (params) => {
  if (params.column.colId !== 'code') return;
  navigator.clipboard.writeText(params.value);
  message.info(`Code copied to clipboard: ${params.value}`)
}

const gridOptions = {
  onCellClicked: handleCellClick,
}

const isExpanded = ref(false);
const gridApi = ref(null);
const gridColumnApi = ref(null);
const onGridReady = (params) => {
  gridApi.value = params.api;
  gridColumnApi.value = params.columnApi;
  params.api.sizeColumnsToFit();
}

const toggleCodes = async (release) => {
  isExpanded.value = !isExpanded.value;
  await adminStore.loadCodes(release);
  setStats();
}

const hasLoadedCodes = computed(() => codes.value[props.release.upc]);

const totalCodes = ref(0);
const totalUsed = ref(0);
const totalRemaining = ref(0);
const filterValue = ref(null);
const setStats = () => {
  const currentCodes = codes.value[props.release.upc];
  totalCodes.value = currentCodes.length * 3;
  totalRemaining.value = currentCodes.reduce((acc, code) => {
    return acc + code.downloads_remaining;
  }, 0);
  totalUsed.value = totalCodes.value - totalRemaining.value;
};

const getTotalUsedPercent = computed(() => {
  return (totalUsed.value / totalCodes.value * 100).toFixed(2);
});

const handleFilterChange = () => {
  gridApi.value.setGridOption('quickFilterText', filterValue.value);
}
</script>

<template>
  <div class="grid-container">

    <div @click="toggleCodes(release)" class="clickable release-clickable">
      <div class="left-side">
        <n-icon><caret-down v-if="isReleaseExpanded[release]" /><caret-forward v-else /></n-icon>
        <div class="release-title">{{ release.title }}</div>
      </div>
      <div class="right-side">
        <div class="sub-info" v-if="hasLoadedCodes">Codes redeemed: <b>{{ totalUsed.toLocaleString('en-US') }} / {{ totalCodes?.toLocaleString('en-US') }} ({{ getTotalUsedPercent }}%)</b></div>
        <div class="sub-info" v-if="props.release.release_date">Release date: <b>{{ props.release.release_date }}</b></div>
      </div>
    </div>
        
    <div v-if="isExpanded" class="releases-container grid-container ag-theme-quartz-dark">

      <n-input v-model:value="filterValue" type="text" placeholder="Filter table" class="filter" @input="handleFilterChange" />

      <ag-grid-vue
        class="grid-container"
        style="height: 500px; width: 100%;"
          :rowData="codes[props.release.upc]"
          :columnDefs="colDefs"
          :gridOptions="gridOptions"
          :defaultColDef="defaultColDef"
          @grid-ready="onGridReady"
        >
      </ag-grid-vue>
    </div>

  </div>
</template>

<style scoped>
.filter {
  max-width: 300px;
  margin: 10px 0;
}
.sub-info {
  font-size: 10px;
  border-radius: 5px;
  /* background-color: var(--sc-blue-dark); */
  padding: 2px 10px;
  margin-right: 10px;
}
.release-title:hover {
  text-decoration: underline;
  /* background-color: var(--sc-blue-dark); */
}
.left-side,
.right-side  {
  display: flex;
  align-items: center;
}
.clickable {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  transition: all 250ms ease-in-out;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 5px 0;
}
.artist-title,
.release-title {
  font-weight: 600;
}
.left-side > *:first-child,
.clickable > *:first-child {
  margin-right: 10px;
}
.artist-name:hover {
  text-decoration: underline;
  /* background-color: var(--sc-blue-dark); */
}
.releases-container {
  padding: 10px 0;
}
.release-clickable {
  display: flex;
  justify-content: space-between;
}
.releases {
  padding: 0px 20px;
}

.grid-container {
  width: 100%;
  height: 100%;
}
</style>