import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons'

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

import './style.css'
import App from './App.vue'
import router from './router';

import ActionsRowRenderer from '@/components/grids/ActionsRowRenderer.vue';

const pinia = createPinia()
const app = createApp(App)
app.component('font-awesome-icon', FontAwesomeIcon)
app.use(router);
app.use(pinia);

app.component(ActionsRowRenderer)
library.add(faArrowRightFromBracket)

app.mount('#app')
