import { defineStore } from 'pinia'
import axios from 'axios';

export const  useAdminStore = defineStore('admin', {
  state: () => {
    return {
      SERVER: 'https://vinylserver.saddle-creek.com',
      users: [],
      codes: {},
      artists: [],
      releases: [],
      sectionTitle: 'Saddle Creek Admin',
    }
  },
  getters: {
    getUsers: (state) => state.users,
  },
  actions: {
    async loadUsers() {
      const results = await axios.get(`${this.SERVER}/users`, { withCredentials: true });
      this.users = results.data;
    },
    async loadArtists() {
      const results = await axios.get(`${this.SERVER}/codes/artists`, { withCredentials: true });
      this.artists = results.data;
      console.debug('Artists loaded', this.artists);
    },
    async loadReleases() {
      const results = await axios.get(`${this.SERVER}/codes/releases`, { withCredentials: true });
      this.releases = results.data;
      console.debug('Releases loaded', this.releases);
    },
    async loadCodes(release) {
      console.debug('Requesting codes for', release);
      const { upc } = release;
      const results = await axios.get(`${this.SERVER}/codes/${upc}`, { withCredentials: true });
      this.codes[upc] = results.data;
    },
    formatDate(timestamp) {
      if (!timestamp) return null;
      const date = new Date(timestamp);
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZoneName: 'short'
      };

      return date.toLocaleDateString('en-US', options);
    },
    removeUser(email) {
      this.users = this.users.filter(u => u.email !== email);
    },
    addUser(email, name) {
      const existingEmails = this.users.map(u => u.email);
      let status = true;
      let message = '';

      if (existingEmails.includes(email)) {
        status = false;
        message = 'Failed to add user: Email already exists';
      };

      if (status) {
        const user = { email, name }
        this.users.push(user);
        message = 'User added successfully';
      }
      return { status, message };
    }
  },
});