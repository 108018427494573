<script setup>
import { useRoute } from 'vue-router';
import { darkTheme } from 'naive-ui'
import { NConfigProvider, NMessageProvider, NDialogProvider } from 'naive-ui'

import AdminHeader from "@/components/header/Header.vue";
import LeftNav from "@/components/left-nav/LeftNav.vue";

const route = useRoute();
const loginRoutes = ['/login', '/logout'];
const themeOverride = {
  common: {
    primaryColor: '#3F97B5',
    primaryColorHover: '#6FB9D8',
    primaryColorPressed: '#3F97B5',
    // warningColor: 'var(--sc-blue-light)',
    // warningColorHover: 'var(--sc-blue-dark)',
    // warningColorPressed: 'var(--sc-blue-dark)',
    // tableColorHover: '#f0f7ff',
    // hoverColor: '#f0f7ff',
    // closeColorHover: 'red'
  },
}
</script>

<template>
  <n-config-provider :theme="darkTheme" :theme-overrides="themeOverride">
    <n-message-provider>
      <n-dialog-provider>
      <div class="app-wrapper">
        <div class="nav" v-if="!loginRoutes.includes(route.path)">
          <LeftNav />
        </div>

        <div class="app-body">
          <div class="header" v-if="!loginRoutes.includes(route.path)">
            <AdminHeader />
          </div>

          <div class="content">
            <router-view></router-view>
          </div>
        </div>
      </div>
      </n-dialog-provider>
    </n-message-provider>
  </n-config-provider>
</template>

<style scoped>
.n-config-provider {
  flex-grow: 1;
  min-height: 100%;
}
.app-wrapper {
  display: flex;
  flex-direction: row;
  min-height: 100%;
  min-width: 100%;
}
.app-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.app-body > div {
  padding: 10px;
}
.header {
  padding: 20px 0;
}
.content {
  border-top: 1px solid var(--sc-blue-dark);
  flex-grow: 1;
}
</style>
